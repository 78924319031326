<template>
  <!--begin::Basic info-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header border-0 bg-default">
      <!--begin::Navs-->
      <div class="d-flex flex-column w-100">
        <div class="d-flex overflow-auto h-55px mb-7">
          <ul
            class="
              nav nav-stretch nav-line-tabs nav-line-tabs-2x
              border-transparent
              fs-5
              fw-bolder
              flex-nowrap
            "
          >
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                to="/tps-map"
                class="nav-link text-active-white text-warning me-6"
                active-class="active"
              >
                Peta Koordinator
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                class="nav-link text-active-white text-warning me-6"
                to="/tps-map/table"
                active-class="active"
              >
                Daftar Saksi
              </router-link>
            </li>
            <!--end::Nav item-->
          </ul>
        </div>
      </div>
      <!--end::Navs-->
      <!--begin::Card title-->
      <div class="card-title m-0 d-flex justify-content-between w-100 mb-4">
        <h3 class="fw-bolder m-0 text-white">Cari Wilayah</h3>
        <div
          class="
            float-end
            d-flex
            align-items-right
            justify-content-between
            w-75
          "
        >
          <select
            name=""
            id=""
            class="form-select me-2"
            @input="getKecamatan"
            v-model="form.kecamatan"
          >
            <option value="" selected>Pilih Kecamatan</option>
            <option
              v-for="(item, i) in kecamatan"
              :key="i"
              :value="item.territory.id"
            >
              {{ item.territory.name }}
            </option>
          </select>
          <select
            name=""
            id=""
            class="form-select me-2"
            @input="getKelurahan"
            v-model="form.kelurahan"
          >
            <option value="" selected>Pilih Kelurahan</option>
            <template v-if="kelurahan">
              <option
                v-for="(item, i) in kelurahan"
                :key="i"
                :value="item.territory.id"
              >
                {{ item.territory.name }}
              </option>
            </template>
          </select>
          <select
            @input="getTps"
            v-model="form.tps"
            name=""
            id=""
            class="form-select w-50"
          >
            <option value="" selected>Pilih TPS</option>
            <template v-if="tps">
              <option v-for="(item, i) in tps" :key="i" :value="item.tps.id">
                {{ item.tps.no_tps }}
              </option>
            </template>
          </select>
        </div>
      </div>
      <!--end::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Content-->
    <div id="kt_account_profile_details" class="collapse show">
      <!--begin::Card body-->
      <div class="card-body p-0">
        <!--begin::Input group-->
        <!-- <div class="row mb-6"> -->
        <div style="height: 64vh; width: 100%">
          <l-map
            v-model="zoom"
            v-model:zoom="zoom"
            :zoomAnimation="true"
            :options="{ zoomControl: false }"
            :center="[-6.304159, 106.724997]"
          >
            <!-- <div
              class="card bg-white rounded position-absolute ms-5 mt-10"
              style="z-index: 2"
            >
              <div class="card-body border-top p-5">
                <div class="fs-5 fw-bolder">Keterangan :</div>
                <ul class="list-unstyled mb-0">
                  <li v-for="(item, i) in info" :key="i" class="m-0 p-0">
                    <img class="me-1" :src="item.img" width="40" />
                    <span class="fs-8"> {{ item.text }} </span>
                  </li>
                </ul>
              </div>
            </div> -->
            <l-tile-layer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              layer-type="base"
              name="OpenStreetMap"
            />
            <l-control-layers />
            <l-control-zoom position="bottomright"></l-control-zoom>
            <template v-for="item in markers" :key="item.tps.id">
              <!-- icon -->
              <l-marker :lat-lng="[item.tps.latitude, item.tps.longitude]">
                <l-icon :icon-url="icon" :icon-size="iconSize" />
                <l-popup>
                  <div class="card h-100 w-100">
                    <div class="card-body p-5">
                      <div class="fw-bolder text-start">
                        {{ item.tps.no_tps }}
                      </div>
                      <div
                        class="
                          row
                          d-flex
                          justify-content-between
                          align-items-center
                        "
                      >
                        <div class="col-4 flex-grow-1">
                          <img
                            :src="item.tps.c_one"
                            class="img-fluid"
                            style="max-height: 70px"
                            alt=""
                          />
                        </div>
                        <div class="col-8">
                          <div class="d-flex align-items-center flex-column">
                            <span>
                              Koordinator:
                              {{ item.coordinator.name }}
                            </span>
                            <span> {{ item.coordinator.phone_number }} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </l-popup>
              </l-marker>
            </template>
          </l-map>
        </div>
        <!-- </div> -->
        <!--end::Input group-->
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->
</template>

<script>
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LControlZoom,
  LPopup,
  // LTooltip,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

export default defineComponent({
  name: "Heatmaps",
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LControlZoom,
    LPopup,
    // LTooltip,
  },
  setup() {
    setCurrentPageTitle("Map TPS");

    const store = useStore();
    // const router = useRouter();

    store.dispatch("AllTerritory", { type: 4 });
    store.dispatch("AllTPS");

    const center = ref([-6.304159, 106.724997]);

    const zoom = ref(12);

    const iconSize = ref([50, 50]);

    const path = window.location.origin + "/";

    const show = ref(false);
    const icon = path + "other/png/Path 2069.png";

    const markers = computed(() => store.state.TPSModule.all);

    const markers2 = ref([
      {
        id: 1,
        position: [-6.30219, 106.729877],
        icon: path + "other/png/Path 2069.png",
        data: {
          name: "TPS 21",
          saksi_img: path + "other/png/Rectangle 1505.png",
          saksi_name: "Kordinator A",
          telp: "088999332276",
          alamat: "Komp Pertamina Blok D1 No.15",
        },
      },
      {
        id: 2,
        position: [-6.303169, 106.721887],
        icon: path + "other/png/Path 2069.png",
        data: {
          name: "TPS 19",
          saksi_img: path + "other/png/Rectangle -4.png",
          saksi_name: "Kordinator B",
          telp: "088999332276",
          alamat: "Komp Pertamina Blok D17 No.2",
        },
      },
    ]);

    const kecamatan = computed(() => store.state.TerritoryModule.kecamatan);
    const kelurahan = computed(() => store.state.TerritoryModule.kelurahan);
    const tps = computed(() => store.state.ResultModule.tps);

    const form = ref({
      kecamatan: "",
      kelurahan: "",
      tps: "",
    });

    // const info = ref([
    //   {
    //     img: path + "other/png/vuesax-bold-user-tag-5.png",
    //     text: "Koordinator TPS",
    //   },
    //   {
    //     img: path + "other/png/Path 2069.png",
    //     text: "Domisili Saksi",
    //   },
    // ]);

    const getKecamatan = (el) => {
      form.value.kelurahan = "";
      form.value.tpsId = "";
      store.commit("SET_TPS_RESULT", { tpses: [] });
      if (el.target.value) {
        store.dispatch("AllTerritory", { type: 5, parent_id: el.target.value });
        updateDataWilayah(el.target.value);
      } else {
        updateTanpaWilayah();
        store.commit("SET_ALL_TERRITORY", { type: 5, territories: [] });
      }
    };

    const getKelurahan = (el) => {
      if (el.target.value) {
        store.dispatch("getTPSResult", { territory: el.target.value });
        updateDataWilayah(el.target.value);
      } else {
        form.value.tpsId = "";
        store.commit("SET_TPS_RESULT", { tpses: [] });
        updateDataWilayah(form.value.kecamatan);
      }
    };

    const getTps = (el) => {
      updateDataTPS(el.target.value);
    };

    const updateDataWilayah = (wilayah) => {
      store.dispatch("AllTPS", { territory: wilayah });
    };

    const updateTanpaWilayah = () => {
      store.dispatch("AllTPS");
    };

    const updateDataTPS = (tps) => {
      const wilayah = form.value.kelurahan
        ? form.value.kelurahan
        : form.value.kecamatan;
      store.dispatch("AllTPS", { territory: wilayah, tps: tps });
    };

    const normalMode = () => {
      // console.log("normal");
      // show.value = false;
    };

    const khususMode = () => {
      show.value = true;
    };

    return {
      center,
      markers,
      markers2,
      // info,
      zoom,
      form,
      iconSize,
      kecamatan,
      kelurahan,
      tps,
      normalMode,
      khususMode,
      show,
      icon,
      getKecamatan,
      getKelurahan,
      getTps,
    };
  },
});
</script>
